
import AxiosConfig from './LensAxiosConfig'
import firebase from '../config/FirebaseConfig'
import axios from 'axios'

let groupCancelToken = null
let breakdownCancelToken = null
// Generates a payload to meet the requirements of its report type
const createGroupPayload = (query, isComparaitive) => {
  const { group, page, limit, sort, selectedRows, filters, currentWindow, comparaitiveQuery, selectedColumns } = query

  return {
    metadata: {
      group_by: group || 'ad_name',
      ...(page && { page }),
      ...(limit && { limit }),
      ...(sort && { sort }),
      ...((!isComparaitive && selectedRows) && { selected_rows: selectedRows }),
      ...(currentWindow && { attr_window: currentWindow })
    },
    query: filters || [],
    // Comparaitive params
    ...((isComparaitive && selectedColumns) && { selected_columns: selectedColumns }),
    ...(isComparaitive && comparaitiveQuery && { custom_group_queries: comparaitiveQuery })
  }
}

// Fetch AD
const getLensGroups = async (lensId, query, reportType) => {
  const { Aa: token } = firebase.auth().currentUser

  const isComparaitive = reportType === 'comparaitive'
  const payload = createGroupPayload(query, isComparaitive)

  // Cancel the previous request if it exists
  if (groupCancelToken) {
    groupCancelToken.cancel('New request incoming, cancelling previous one.')
  }

  groupCancelToken = axios.CancelToken.source()

  console.log('Report Groups Query:', reportType, groupCancelToken)
  const AD_GROUPS_URL = !isComparaitive ? `/ads/groups/${lensId}` : `/ads/custom-groups/${lensId}`
  const { data } = await AxiosConfig.post(
    AD_GROUPS_URL,
    payload,
    {
      cancelToken: groupCancelToken.token,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return data
}

const getLensGroupAds = async (lensId, query) => {
  const { Aa: token } = firebase.auth().currentUser

  // Cancel the previous request if it exists
  if (breakdownCancelToken) {
    breakdownCancelToken.cancel('New request incoming, cancelling previous one.')
  }

  breakdownCancelToken = axios.CancelToken.source()

  const payload = {
    metadata: {
      group_by: 'ad_name',
      page: 1,
      limit: 10,
      sort: {
        by: 'spend',
        order: 'desc'
      },
      attr_window: query.currentWindow
    },
    query: query.filters || [],
    custom_group_queries: query.comparaitiveQuery || [],
    selected_columns: query.selectedColumns || []
  }
  const AD_GROUPS_URL = `/ads/custom-group/${lensId}`
  const { data } = await AxiosConfig.post(
    AD_GROUPS_URL,
    payload,
    {
      cancelToken: breakdownCancelToken.token,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return data
}

const getLensInsights = async (query) => {
  const { Aa: token } = firebase.auth().currentUser
  const { page, search } = query || {}
  const payload = {
    metadata: {
      group_by: 'ad_name',
      page: page || 1,
      limit: 10,
      sort: {
        by: 'spend',
        order: 'desc'
      }
    },
    query: [
      [
        {
          key: 'event_date',
          value: 'last_7_days',
          operation: 'greater_than'
        },
        {
          key: 'event_date',
          value: 'today',
          operation: 'less_than'
        }
      ]
    ]
  }
  const searchParam = search ? `?q=${search}` : ''

  const { data } = await AxiosConfig.post(
    `/ads/insights${searchParam}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
  return data
}

const abortGroupRequest = () => {
  if (groupCancelToken) {
    groupCancelToken.cancel('Group request cancelled by user.')
  }
}

const abortBreakdownRequest = () => {
  if (breakdownCancelToken) {
    breakdownCancelToken.cancel('Breakdown request cancelled by user.')
  }
}

export default {
  getLensGroups,
  getLensGroupAds,
  getLensInsights,
  // Utils
  abortGroupRequest,
  abortBreakdownRequest
}
